import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ProductCard from './ServicesCard';

function ProductCardDetails({ data }: any) {
    return (
        <div className='common-background bg-cover'>
            <Container className="mx-auto mt-4 mb-3 mt-lg-5">
            <h3 className='color-lightGreen mb-3 mb-lg-4 text-center fnt-14 fnt-md-16  fw-700 text-uppercase'>categories</h3>
                <div className=' mb-lg-5'>
                    <Row xs={1} sm={1} md={2} lg={3} xl={3} xxl={3}>
                        {data.serviceCardDetails.map((productcard: any, index: any) => (
                            <Col key={productcard.id} >
                                <ProductCard  {...productcard} />
                            </Col>
                        ))}
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default ProductCardDetails