import React from 'react'
import { Container } from 'react-bootstrap'

function ProductBottom({ bottom_header, bottom_desc }: { bottom_header: string, bottom_desc: string }) {
  return (
    <div className='min-vw-100 d-flex align-items-center pt-lg-5 pt-4 pb-0 bg-white'>
      <div className='d-flex flex-column'>
        <Container className=''>
          <p className='color-lightGreen mb-3 text-center fnt-14 fnt-md-16  fw-700'>OVERVIEW</p>
          <div className='d-flex justify-content-center align-items-center text-center'>
            <p className='fw-700 fnt-20 fnt-lg-32   mb-3  product-bottom'>{bottom_header}</p>
          </div>

        </Container>
        <div className='service-bottom-bg d-flex justify-content-center'>
          <p className='fw-400  fnt-14 fnt-lg-16 pb-lg-5 pb-4 text-center  m-0 ideation-para'>{bottom_desc}</p>
        </div>
      </div>
    </div>
  )
}

export default ProductBottom